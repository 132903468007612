/*
Template Name: Skote - Responsive Bootstrap 4 Admin Dashboard
Author: Themesbrand
Version: 1.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";

.btn-label .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: hsla(0,0%,100%,.1);
    border-right: 1px solid hsla(0,0%,100%,.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}