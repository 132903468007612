.participant-header {
    background-image: url(../images/study_header.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    height: $study-header-height;
    line-height: $study-header-height;
    width: 100%;
    background-color: #034a6d;
    color: white;
    font-size: 1.4em;
    text-align: center;
}

.participant-content {
    height: calc(100vh - $study-header-height - $study-footer-height);
    
    .content {
        padding: 0 15px 10px 15px;
    }
    // .btn-primary {
    //     background-color: #034A6D;
    //     border-color: #034A6D;
    //     color: $white;
    //     box-shadow: 0 0 0 2px rgba(#034A6D, .5);

    //     &:hover, &:active, &:not(:disabled):not(.disabled):active {
    //         background-color: #082842;
    //         border-color: #082842;
    //     }
    // }

    .single-page-form {
        .single-page-buttons {
            button:disabled {
                background-color: #b4c8d0;
                border: #b4c8d0;
            }
        }
    }

    .foldable {
        width: 100%;
    }
    // .text-info {
    //     color: #00245d !important;
    // }
}

.participant-footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: $footer-color;
    left: 0;
    height: $study-footer-height;
    background-color: $footer-bg;
}