//
// _buttons.scss
//

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-label .label-icon {
  position: absolute;
  width: 32px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: hsla(0,0%,100%,.1);
  border-right: 1px solid hsla(0,0%,100%,.1);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}