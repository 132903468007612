@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);

.noti-icon .badge{
    left :23px;
}
.mt-2
{
 margin-top: 20px!important;
}

.dataTables_filter, .dataTables_paginate
{
    float: right;
}

.rdw-editor-main
{
    border: 1px solid #eeeef5;
    height: 239px;
}
.dz-message
{
     text-align: center;
    padding: 100px;
}
.fcYNFc
{
        background-color: #ffffff!important;
}
.task-box
{
    border : 1px solid #e1e1e6;
}
.react-datepicker-wrapper
{
    width: 100%!important;
}

.ReactModal__Overlay
{
  z-index: 1001!important;
}

.fc-event .fc-content{
    padding: 5px;
    color: #FFF;
}
.chat-conversation .right .conversation-list {
    margin-right: 15px;
}

.external-event{
    &:hover{
        cursor: pointer;
    }
}

#sidebar-menu {
    ul {
        li {
            a {
                p {
                    margin-bottom: 0;
                }
                i, svg {
                    font-size: 1rem;
                }
            }

            .badge {
                color: $sidebar-menu-sub-item-color;
                background-color: #feffff47;
            }

            ul.sub-menu {
                a {
                    justify-content: space-between;
                }
                &.mm-collapse {
                    display: block;
                    transition: all ease-out 0.2s;
                    overflow: hidden;

                    &:not(.mm-show) {
                        max-height: 0;
                    }
                }
            }
        }
    }
}

.tooltip {
    font-size: 0.6rem;
    pointer-events: none;
    // transition-delay: .35s;
    background-color: transparent !important;
    border: none !important;
    z-index: 1001;
}

.breadcrumb-link {
    color: #495057;
    &:hover {
        color: #495057DD;
    }
}

.page-content {
    // input, .react-date-picker, button {
    //     margin-bottom: 1rem;
    // }

    .input-group-append {

        .input-group-text {
            width: 131px;
            justify-content: center;
            // background-color: #fff;
            border: 1px solid #ced4da;
        }
    }

    .react-date-picker__wrapper, .react-time-picker__wrapper {
        border: none !important;
    }

    .react-date-picker__calendar {
        z-index: 1005;

        .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
            padding: 1.3em 0.5em;
        }
    }

    .loader-container {
        min-height: calc(100vh - 154px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .boolean-checkbox {
        display: inline;

    }

    .table-responsive {
        overflow-x: visible;
    }

    table.mdb-dataTable thead .sorting_asc:before, table.mdb-dataTable thead .sorting_desc:after {
        top: .5rem;
    }

    .mdb-dataTables_paginate {
        display: none;
    }

    .mdb-dataTable tbody tr td[colspan]{
        text-align: center;
    }



    formcontrol.disabled {
        border-color:#a4a8bd;
        background-color: #fafafa;
    }

    .form-control.autocomplete {
        input {
            border: none;
            width: 100%;
            height: 100%;
            &:focus, &:active, &:active {
                border: none !important;
            }
        }
    }

    .memo-wrapper {
        padding-bottom: 20px;
        width: 100%;
    }

    .formLockLabel {
        min-width: 160px;
        max-width: 160px;
    }

    .showLockIconsLabel {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
    }
}

.login-page {
    .login-notice {
        color: orange;

        &:hover {
            color: rgb(206, 136, 6);
        }
    }
}

.login-page-small
{
	font-size: 0.8em;
}

label.disabled {
    color: $disabled;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: $disabled;
}

.form-control:disabled, input:disabled, .form-control.autocomplete:disabled {
    background-color: #fcfcfc;
    // color:#a4a8bd;
    color: #6f777e;
}

.form-control.btn-primary:disabled {
    background-color: #556ee6;
    border-color: #556ee6;
}

.textSectionTitle
{
	font-size: 1.15em;
	padding-bottom: 6px;
	color: #200020;
}

.validation-error {
    display: block;
    width: 100%;
    margin-bottom: .25rem;
    font-size: 80%;
    color: #f46a6a;
}

.validation-warning {
    display: block;
    width: 100%;
    margin-bottom: .25rem;
    font-size: 80%;
    color: #f5a141;
}

// .account-pages {

//     .avatar-md {
//         position: absolute;
//     }

//     .login-dash-title {
//         padding-top: 30px;
//         padding-left: 110px;
//         padding-bottom: 20px;
//         display: block;
//     }
// }

.save-btn {
    position: relative;
    .spinner.spinner-border {
        position: absolute;
        height: 1.5rem;
        width: 1.5rem;
        left: calc(50% - 0.75rem);
    }
}

.form-control.autocomplete {
    padding: .375rem 1.75rem .375rem .75rem;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23495057' d='M2 4L0 2h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}

.field-history-table, .field-query-table {
    margin-top: 1rem;
    .date {
        width: 150px;
    }
    .user {
        width: 150px;
    }

    .light-red-bg {
        background-color: #fac0c0;
    }

    .red-border {
        border: 3px solid red;
    }
}

.field-query-table {
    margin-top: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {
    background-color: transparent;
    color: #495057 !important;
}

.btn-group-toggle {
    .btn-success {
        background-color: #74788d;
        border-color: #74788d;
    }


    .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active {
        background-color: #5d6071;
        border-color: #5d6071;
    }

    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus {
        box-shadow: none;
    }

    .btn-success:focus {
        box-shadow: none;
    }

}

.table-sm {
    .btn-sm {
        line-height: 1;
    }
}

.custom-switch {
    .custom-control-label {
        &::before, &::after {
            cursor: pointer;
        }
    }
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.task-list-table {
    thead {
        tr {
            th {
                i {
                    color: #aaaaaa;
                    padding-left: 5px;
                    padding-right: 0px;
                    cursor: pointer;
                }
            }
        }
    }
}

.sigCanvas {
    border: 1px solid black;
}

.dropdown-item {
    cursor: pointer;
}

.single-page-form {

    .single-page-buttons {
        display: flex;
        // justify-content: space-between;

        button {
            width: 150px;
            margin-right: 20px;

            &:disabled {
                background-color: gray;
                border: darkgray;
            }
        }
    }

    .single-page-hint-line {
        font-size: 10px;
        color: black;
    }
}

.lock-icon {
    width: 50px;
}

.pdfViewer {
    width: 100%;
    min-height: 500px;
}

.sortable-table {
    .fa-sort, .fa-sort-asc, .fa-sort-desc {
        color: #aaaaaa;
        padding-left: 5px;
        padding-right: 0px;
        cursor: pointer;
    }
}

.bd-highlight
{
	background-color: rgba(86,61,124,.15);
    border: 1px solid rgba(86,61,124,.15);
}

.ag-header-align-right .ag-header-cell-label {
    justify-content: end;
}

.ag-header-align-left .ag-header-cell-label {
    justify-content: start;
}

.custom-date-picker {

    .react-datepicker__header {
        background: #556ee6;
    }
    
    .input-group-text {
        padding: 0;
        border: none;

        button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.input-group-text {
    padding: 0;
    border: none;
}

.react-datepicker-popper {
    z-index: 1001;
}

.btn {
    &.btn-xs {
        padding: 0.2rem 0.5rem;
    }
}

.react-datepicker__header {
    background: #556ee6;

    select {
        flex: 1;
        text-align: center;
        background: transparent;
        color: white;
    }

    option {
        color: black;
    }

    .react-datepicker__day-name {
        color: white;
    }
}

.login-header {
    position: relative;
    
    .title {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
            width: 80%;
        }
    }

}

.btn-login {
    background-color: #034a6d;
    border-color: #034a6d;
    color: white;

    &:hover, &:active, &:focus {
        background-color: #023853;
        border-color: #023853;
        color: white;
    }
}

.text-darkblue {
    color: #034a6d;
}

.btn-light-login {
    background-color: #ffffff;
    border-color: #034a6d;
    color: #034a6d;

    &:hover, &:active, &:focus {
        background-color: #f0eeee;
        border-color: #023853;
        color: #023853;
    }
}

.sort-icon {
    color: #aaaaaa;
    margin-left: 5px;
    cursor: pointer;
    width: 14px;
}

.table th, .table td {
    vertical-align: middle;
}

.table thead th {
    white-space: nowrap;
    font-weight: bold;
    border: none;
}

.table-bordered th, .table-bordered td {
    border: none;
}

.table-bordered {
    border: none;
}

.gear-table {
    th, td {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .copy-cell {
        cursor: pointer;

        &:active {
            outline: 1px solid $header-bg;
        }
    }
}

// Remove default indentation for global
// .row {
//     margin-left: 0;
//     margin-right: 0;
// }

// .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
//     padding-left: 0;
//     padding-right: 0;
// }

/** Input field styles */

/** Start MultiUnitInput styles */

.multi-unit-select {
    appearance: none;
    -webkit-appearance:none; /* safari and chrome */
    -moz-appearance:none; /* Mozilla */
    -ms-appearance:none; /* Ie */
    position: relative;
    background: url(../images/arrow-down.png) no-repeat right; 
    background-position: calc(100% - 6px) center !important;
    // background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    padding: 10px 30px 10px 10px;
    font-size: 0.7rem;
    height: 45.17px;
}

.multi-unit-input-field {
    width: 300px;
}

.oz-input-field {
    width: 190px;
}

.multi-unit-append {
    width: 50px;
}

.multi-unit-description {
    margin: 0;
    color: #303030;
}
/** End MultiUnitInput styles */

.unit-input-field {
    max-width: 250px;

    .input-group-append {
        width: 60px;
    }
}

.form-field-group {
    display: flex;
    flex-wrap: wrap;

    > div {
        padding-right: 20px;
    }

    @media only screen and (max-width: 678px) {
        & {
            > div {
                width: 100%;
            }
        }
    }
}

.custom-slider {
    margin-top: 50px;
    margin-bottom: 60px;
}

.progress {
    // margin-bottom: 1.25rem;
}

.date-input-field {
    width: 200px;
}

.drop-down {
    min-width: 240px;
}

// .col-form-label {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

.traceSection {
    font-size: 10pt;
    color: #372e10;
    margin-bottom: 10px;

    & > div {
        background-color: #d2c9ab;
        padding: 4px 10px;
        border: 1px solid #854207;
    }
}

/** Layout styles */

.main-content {
    height: 100vh;
    padding-top: $header-height;
    padding-bottom: $footer-height;
}

.page-content {
    height: 100%;
    overflow: auto;
    padding: $grid-gutter-width $grid-gutter-width / 2;
    // padding-right: $grid-gutter-width / 2;
    // width: calc(100vw - 280px);
    // overflow-x: hidden;
    // position: relative;
}

// .container-fluid {
//     padding: 0;
// }

.footer {
    height: 84px;
    left: 280px;
    z-index: 3;
    overflow: hidden;

    .dark {
        color: #666666;
    }

    .light {
        color: #a3a3a3;
        font-size: 0.8em;
    }

    @media (max-width: 992px) {
        & {
            left: 0;
        }
    }
}

.foldable {
    position: absolute;
    bottom: 84px;
    z-index: 3;
    // background: #56a6ed;
    width: calc(100% - 280px);
    margin-left: -36px;
    // padding: 12px 12px 12px 36px;
    padding: 0;
    cursor: pointer;
    max-height: 50%;
    overflow: auto;

    @media (max-width: 992px) {
        & {
            width: 100%;
        }
    }

    .foldable-title {
        padding: 12px 12px 12px 36px;
        font-size: 20px;

        .query-form-btn {
            font-size: 20px;
        }
    }

    .action-title {
        margin-right: 10px;
    }

    .foldable-content {
        padding: 0 12px 0 36px;
    }

    .collapse {
        max-height: 50%;
    }

    &.alert-warning {
        background-color: #fcf3cf;
        border-top: 1px solid #856404;

        .foldable-content {
            background-color: #fefaee;
        }
    }

    &.alert-primary {
        background-color: #b8daff;

        .foldable-content {
            background-color: #cce5ff;
        }
    }
    
    &.alert-danger {
        background-color: #f7cccc;

        .foldable-content {
            background-color: #fceded;
        }
    }
}

.record-foldable {
    cursor: pointer;

    .foldable-title {
        padding: 12px;
    }
    
    .action-title {
        margin-right: 10px;
    }

    &.open-queries {
        background-color: #f7cccc;

        .foldable-content {
            background-color: #fceded;
        }
    }

    &.closed-queries {
        background-color: #c5b9b9;
    }
}

.manage-user {
    .profile-details {
        .row {
            min-height: 50px;
        }
    }
}

.dataTables_length {
    padding-left: 12px;

    select {
        margin-left: 0 !important;
    }
}

.dataTables_info {
    padding-left: 12px;
}

.usersTable {
    & > .row:first-child {
        display: inline-flex;

        & > div:last-child {
            margin-top: 14px;
        }
    }

    & td span {
        &.red {
            color: #ea3b43;
        }

        &.green {
            color: #38831a;
        }
    }
}

.users-type-select {
    position: absolute;
    left: 455px;
    width: 180px;
}

.users-status-select {
    position: absolute;
    left: 670px;
    width: 180px;
}